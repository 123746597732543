<template>
  <div id="online_courses_info_video" class="mycourses">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <section class="course_title">
      <div class="w1400 bg-deco">
        <img class="bg-deco-img" src="@/statics/img/index/deco_heart_r.webp" alt="">
      </div>
      <div class="w1100">
        <h3 class="title txt-bold">{{ video.name }}</h3>
        <div class="course_info">
          <p class="episode"><small>NO.{{ unitNum }}</small></p>
          <p class="course_name">{{ course.name }}</p>
          <p class="video_length">單元時長: {{ video.length }}</p>
          <p class="period">觀看/測驗期限: {{ computedExpiryTime }}</p>
          <p>最佳瀏覽體驗建議瀏覽器: Edge、Firefox、Chrome、Safari ( 螢幕設定最佳顯示效果為1920*1080 )</p>
        </div>
      </div>
    </section>

    <div class="course_description" v-if="video.content">
      <div class="w1100">
        <p class="txt-bold">單元說明</p>
        <p>{{ video.content }}</p>
      </div>
    </div>

    <div class="course_video">
      <div class="w1100 demo">
        <div
          class="video-wrapper"
          :style="{ maxHeight: videoWrapperMaxHeight }"
        >
          <resize-observer @notify="updateVideoWrapperMaxHeight" />
          <div class="video-container">
            <youtube
              ref="youtube"
              :video-id="youtubeID"
              :video-height="videoHeight"
              :video-width="videoWidth"
              :player-vars="playerVars"
              @playing="playing"
              @paused="paused"
              @ended="ended"
            ></youtube>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!isVideoOver">
      <p v-if="!this.isPlaying" class="h3 txt-light_green txt-center mg-t-30">點擊播放後，可選擇跳至上次觀看進度</p>
      <p v-else class="h3 txt-red txt-center mg-t-30">點擊影片畫面暫停，可紀錄您的觀看進度</p>
    </template>

    <div class="timer txt-center mg-t-30">
      <p class="h3 txt-light_green" v-if="videoLength !== null">{{ hhmmss(videoLength) }}</p>
    </div>

    <div class="btn_wrap without_heart_btn">
      <div
        v-if="!isVideoOver && isPlaying"
        id="btn_wrapper"
        class="btn_wrapper btn_advance"
        :class="{active: advancedTime}"
      >
        <div class="cover"></div>
        <button type="button" class="btn" @click="seekToAdvanceTime()">
          上次觀看紀錄
        </button>
      </div>
      <div
        id="btn_wrapper"
        class="btn_wrapper"
        :class="{active: isVideoOver}"
      >
        <div class="cover"></div>
        <button type="button" class="btn" @click="goToTest">
          前往測驗題目
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/** Libraries */
import _ from 'lodash';
import moment from 'moment';

/** Components */
import PageSubHeader from '@/components/PageSubHeader.vue';

/** Functions */
import { RegisterOnlineSubHeader } from '@/lib/const';
import { coursesView, videoProgress, videoStatus } from '@/lib/http';
import { mapActions } from 'vuex';
import { hhmmss } from '@/lib/public';

let timer;

export default {
  name: 'RegisterOnlineMycourseVideoCopy',
  data() {
    return {
      ...RegisterOnlineSubHeader,
      courseID: '',
      videoID: '',
      unitNum: '',
      videoWrapperMaxHeight: '620px',
      videoHeight: '560',
      videoWidth: '315',
      playerVars: {
        controls: 0,
        fs: 0,
        modestbranding: 1,
        rel: 0,
        playsinline: 1,
        autoplay: 0,
        /** 開啟/關閉 鍵盤操作影片功能(快進、快退...等) */
        disablekb: 1,
        /** 可使用IFrame API來控制影片 */
        enablejsapi: 1,
      },
      videoTotalLength: null,
      videoLength: null,
      isPlaying: false,
      isVideoOver: false,
      /**
       * API Data
       */
      course: {},
      video: {},
      advancedTime: null,
    };
  },
  created() {
    this.courseID = this.$route.query.course_id;
    this.videoID = this.$route.query.video_id;
    this.unitNum = this.$route.query.unit;

    coursesView({
      uuid: this.courseID,
    }).then((result) => {
      this.course = result.data;

      /** 已購買 */
      if (this.course.bought_completed === false
        || this.course.bought_completed === true
      ) {
        /** 有限期 */
        if (this.course.expiry_time && this.course.expiry_time > 0) {
          /** 未過期 */
          if (moment(this.course.bought_expiry_time).isAfter()) {
            /**
             *  檢查影片觀看進度，若已看過則直接開啟測驗按鈕
             */
            videoProgress({
              video_uuid: this.videoID,
            }).then((res) => {
              this.advancedTime = _.get(res, 'data.result.query_time', null);
              if (res.data.result.completed * 1 === 2) {
                this.isVideoOver = true;
              }
            });
            this.course.course_videos.forEach((video) => {
              if (video.uuid === this.videoID) {
                this.video = video;
              }
            });
          /** 已過期 */
          } else {
            this.openModal({
              url: '/register/online/index',
              message: '課程已過期，請返回課程總覽',
            });
          }
        /** 無限期 */
        } else {
          /**
           *  檢查影片觀看進度，若已看過則直接開啟測驗按鈕
           */
          videoProgress({
            video_uuid: this.videoID,
          }).then((res) => {
            if (res.data.result.completed * 1 === 2) {
              this.isVideoOver = true;
            }
          });
          this.course.course_videos.forEach((video) => {
            if (video.uuid === this.videoID) {
              this.video = video;
            }
          });
        }
      /** 未登入、未購買 */
      } else {
        this.openModal({
          url: '/register/online/index',
          message: '您尚未登入或購買該課程，請返回課程總覽',
        });
      }
    });
  },
  destroyed() {
    clearInterval(timer);
    timer = false;
  },
  computed: {
    computedExpiryTime() {
      /** 無期限 */
      if (!this.course.expiry_time) {
        return '無限制';
      }
      /** 課程已購買 */
      if (this.course.bought_completed === false || this.course.bought_completed === true) {
        return this.course.bought_expiry_time;
      }
      /** 課程未購買 */
      return `${this.course.expiry_days}天`;
    },
    youtubeID() {
      if (this.video.link) {
        return this.video.link.split('/').slice(-1).pop();
      }
      return null;
    },
    player() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    updateVideoWrapperMaxHeight() {
      const videoWrapper = document.querySelector('.video-wrapper');
      const newHeight = (parseFloat(getComputedStyle(videoWrapper, null).width.replace('px', '')) * 620) / 1100;
      this.videoWrapperMaxHeight = `${newHeight}px`;
    },
    setTimer(advanced = null) {
      /** 取得影片總時長(秒數)，並將計時器顯示於影片下方 */
      this.player.getDuration().then((success) => {
        this.videoTotalLength = Math.floor(success);
        const target = this;

        if (this.videoLength === null) {
          this.videoLength = Math.floor(success);
        }
        if (advanced) {
          this.videoLength = Math.floor(success) - advanced;
        }
        if (!timer) {
          timer = setInterval(() => {
            if (target.videoLength && target.videoLength * 1 > 0) {
              target.videoLength -= 1;
            }
            if (target.videoLength * 1 <= 0) {
              clearInterval(timer);
              timer = false;
              target.videoLength = 0;
            }
            // console.log('remaining seconds', `${target.videoLength}s`);
          }, 1000);
        }
      });
    },
    playing() {
      this.isPlaying = true;
      this.setTimer();
      videoStatus({
        video_uuid: this.videoID,
        type: 1,
      });
    },
    paused() {
      clearInterval(timer);
      timer = false;
      let currentTime = null;
      this.player.getCurrentTime().then((success) => {
        currentTime = Math.floor(success);
        // console.log('proceeding seconds', `${currentTime}s`);
        videoStatus({
          video_uuid: this.videoID,
          type: 1,
          query_time: currentTime,
        });
      });
    },
    ended() {
      videoStatus({
        video_uuid: this.videoID,
        type: 2,
      });
      this.isVideoOver = true;
    },
    seekToAdvanceTime() {
      if (this.advancedTime) {
        this.videoLength = this.videoTotalLength - this.advancedTime;
        this.player.seekTo(this.advancedTime, true);
      }
    },
    goToTest() {
      if (this.isVideoOver) {
        this.$router.push({
          path: `/register/online/mycourse/exam?
          course_id=${this.courseID}&unit=${this.unitNum}&video_id=${this.videoID}`,
        });
      }
    },
    hhmmss,
  },
  components: {
    PageSubHeader,
  },
};
</script>
